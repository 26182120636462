// SignInModal.jsx

import React, { useState, useEffect } from "react";
import "./SignInModal.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../api/auth";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import ResetPassword from "../../components/ResetPasswordPopup/ResetPassword";

const SignInModal = ({ isOpen, onClose }) => {
  // Call hooks first, before early return
  const [loading, setLoading] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const navigate = useNavigate();
  const { storeTokenInLS, API } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  // Fetch data on mount
  useEffect(() => {
    // Fetch departments, schemes, and semesters data
    const fetchData = async () => {
      try {
        const response = await fetch(`${API}/api/cdss`);
        const data = await response.json();

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [API]);

  const [formData, setFormData] = useState({
    usn: "",
    password: "",
  });

  // Early return if modal is not open
  if (!isOpen) return null;

  const ShowResetPassword = () => {
    setShowResetPassword(true);
  };

  const handleClosePopup = () => {
    setShowResetPassword(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedUsn = name === "usn" ? value.toUpperCase() : value;
    setFormData({ ...formData, [name]: updatedUsn });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // const response = await fetch(`http://localhost:5000/api/login`, {
      const response = await fetch(`${API}/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      // Handle response based on success or failure
      // console.log("Login Form");

      if (response.ok) {
        const res_data = await response.json();

        // console.log("Response from Server", res_data);
        storeTokenInLS(res_data.token);

        toast.success("Ha Hogaya login");

        navigate("/Welcome");

        setFormData({ usn: "", password: "" });

        setLoading(false); // Ensure the loading state is cleared
      } else {
        toast.error("Nice try! But aap galat ho");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
    onClose();
  };

  return (
    <div className="n-modal-overlay">
      <div className="n-modal-content">
        <button className="n-close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Sign In</h2>

        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <form className="n-login-form" style={{ width: "100%" }}>
              <div className="n-login-form-ip">
                <input
                  type="text"
                  name="usn"
                  value={formData.usn}
                  onChange={handleInputChange}
                  required
                />
                <span>USN</span>
              </div>

              <div className="n-login-form-ip">
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
                <span>Password</span>
              </div>
              <span className="n-forget-password" onClick={ShowResetPassword}>
                Forget Password ?
              </span>
              <button
                type="submit"
                className="n-custom-button filled"
                style={{ width: "90%", padding: "16px", width: "100%" }}
                onClick={handleLogin}
                disabled={loading}
              >
                {loading ? <Spinner /> : "Sign In"}
              </button>
            </form>
          </>
        )}

        <div className="n-or-divider-login">Or</div>
        <p className="n-l-link-text">
          Don't have an account?{" "}
          <Link to="/SignUp" className="n-sign-up-link">
            Sign Up
          </Link>
        </p>
      </div>
      {showResetPassword && <ResetPassword onClose={handleClosePopup} />}
    </div>
  );
};

export default SignInModal;
